import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import FormPharmacy from './FormPharmacy';
import FormProvider from './FormProvider';
import { bindActionCreators } from 'redux';
import * as mainActions from '../actions/main';
import { connect } from 'react-redux';

class Main extends Component {

    render() {
        return (
            <>
            <Tabs style={{paddingTop: '75px', weight: 'bold', fontSize: '20px', color: '#aaa'}}>
                <TabList>
                    <Tab>Soy una farmacia</Tab>
                    <Tab>Soy un proveedor</Tab>
                </TabList>
                <TabPanel>
                    <FormPharmacy></FormPharmacy>
                </TabPanel>
                <TabPanel>
                    <FormProvider></FormProvider>
                </TabPanel>
            </Tabs>
            </> 
        )
    }
}
const mapStateToProps = (state) => ({
    main: state.main
});

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(mainActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);