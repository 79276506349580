import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from "react-toastify";
import * as mainActions from '../actions/main';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Card from '@material-ui/core/Card';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import Save from '@material-ui/icons/Save';
import API_URL from '../config/constants';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ReCAPTCHA from 'react-google-recaptcha';
import {DropzoneArea} from 'material-ui-dropzone'


const HDT_DENO = 'hdt_deno';

class FormProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            captchaOK : false,
            saving: false,
            loggedIn: false,
            dropzonekey: new Date().getTime().toString(),
            form: {
                cif: '',
                clasificacion: '',
                obs: '',
                files: [],
            },
            login: {
                user: '',
                password: '',
            },
            errors: {
                user: '',
                password: '',
                cif: '',
                clasificacion: '',
                obs: '',
            },
            errorsLogin: {
                user: '',
                password: '',
            },
            types: [],
            clasifications: [{ codex: "000001", deno: "Servicio técnico" }, { codex: "000002", deno: "Dudas farmatic" }]
        }
        this._reCaptchaRef = React.createRef();
    }

    async componentDidMount() {
        const requestTipos = new Request(`${API_URL}/getTipos`, {
            method: 'POST',
            body: JSON.stringify({
                username: 'csm',
                password: 'csm',
            }),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        });
        const responseTipos = await fetch(requestTipos);
        const jsonTipos = await responseTipos.json();

        const requestClasificaciones = new Request(`${API_URL}/getClasificaciones`, {
            method: 'POST',
            body: JSON.stringify({
                username: 'csm',
                password: 'csm',
            }),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        });
        const responseClasificaciones = await fetch(requestClasificaciones);
        const jsonClasificaciones = await responseClasificaciones.json();
        this.setState({ types: jsonTipos.result.data.hd_tipos.data, clasifications: jsonClasificaciones.result.data.clasificacion.data})
    }

    setValue = (event) => {
        const { form } = this.state;
        form[event.target.name] = event.target.value;
        this.setState({ form });
    }

    setLoginValue = (event) => {
        const { login } = this.state;
        login[event.target.name] = event.target.value;
        this.setState({ login });
    } 

    login = async () => {
        if (this.validateLoginFields()) {
            this.setState({saving: true});
            const formData = this.state.login;
            const request = new Request(`${API_URL}/login_proveedor`, {
                method: 'POST',
                body: JSON.stringify({
                    username: 'csm',
                    password: 'csm',
                    data: formData
                }),
                headers: new Headers({
                    'Content-Type': 'application/json'
                })
            });
            const response = await fetch(request);
            const json = await response.json();
            this.setState({saving: false});

            if (json.result.data.code === 200) {
                this.resetForm();
                this.setState({ loggedIn: true, clasifications: json.result.data.hd_tipos.data });
                return toast("Login Correcto.", {
                    className: "toast-success"
                });
            } else if (json.result.data.code === 401) {
                return toast("Los datos de usuario o contraseña no son correctos.", {
                    className: "toast-error"
                });
            } else {
                return toast(json.msg, {
                    className: "toast-error"
                });
            }
        }
    }

    save = async () => {
        if (this.validateFields()) {
            this.setState({saving: true});
            const formData = {...this.state.form, ...this.state.login};
            const request = new Request(`${API_URL}/insert_helpdesk_proveedores`, {
                method: 'POST',
                body: JSON.stringify({
                    username: 'csm',
                    password: 'csm',
                    data: formData
                }),
                headers: new Headers({
                    'Content-Type': 'application/json'
                })
            });
            const response = await fetch(request);
            const json = await response.json();
            this.setState({saving: false});

            if (json.code === 200) {
                this.resetForm();
                return toast("OK, incidencia generada correctamente.", {
                    className: "toast-success"
                });
            } else if (json.result.data.code === 501) {
                return toast("No se puede identificar al cliente con los datos proporcionados.", {
                    className: "toast-error"
                });
            } else {
                return toast(json.msg, {
                    className: "toast-error"
                });
            }
        }
    }

    resetForm = () => {
        let newForm = {};
        let newErrors = {};

        const formFields = Object.keys(this.state.form);
        formFields.forEach(field => {
            newErrors[field] = ''
            newForm[field] = ''
        });
        newForm.files = []; //unico campo que no es texto
        this.setState({ dropzonekey: new Date().getTime().toString(), form: newForm, errors: newErrors });
    }

    validateLoginFields = () => {
        const formData = this.state.login;
        const formFields = Object.keys(this.state.login);
        let newErrors = {}
        let existsErrors = false;
        formFields.forEach(field => {
            if (!formData[field]) {
                existsErrors = true;
                newErrors[field] = 'No puede estar vacío'
            } else {
                newErrors[field] = ''
            };
        });

        this.setState({ errorsLogin: newErrors });
        return !existsErrors;
    }

    validateFields = () => {
        const formData = this.state.form;
        const formFields = Object.keys(this.state.form);
        let newErrors = {}
        let existsErrors = false;
        formFields.forEach(field => {
            if (!formData[field]) {
                existsErrors = true;
                newErrors[field] = 'No puede estar vacío'
            } else {
                newErrors[field] = ''
            };
        });

        this.setState({ errors: newErrors });
        return !existsErrors;
    }

    onChangeFiles = (files) => {
        //console.log(files);
        const newFiles = [];
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                //console.log(file.type);
                const newFile = {};
                //console.log(JSON.stringify(event.target));
                newFile.base64 = event.target.result.split(',')[1]; //get only base64 content
                newFile.type = event.target.result.split(';')[0].split(':')[1]; //remove data:image/.... 
                //console.log(newFile.type);
                //console.log(newFile.base64.substring(0,20));
                newFiles.push(newFile);
                this.setState(prevState => ({
                    form: {                   // object that we want to update
                        ...prevState.form,    // keep all other key-value pairs
                        files: newFiles,      // update the value of specific key
                    }
                }));
            };
            reader.readAsDataURL(file);
         });

        //console.log(this.state.form.files);
    }

    onCaptchaOK = () => {
        this.setState({captchaOK : true});
    }

    render() {
        const { form, login, errors, errorsLogin, clasifications, saving, captchaOK, dropzonekey } = this.state;
        return (
            <>   
                { !this.state.loggedIn && 
                    <>
                    <Card style={{ margin: '5em 8vw', padding: '1em' }}>

                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <span style={{ weight: 'bold', fontSize: '20px', color: '#0095a8' }}>Introduzca su usuario y contraseña</span>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl style={{ width: '100%' }}>
                                    <TextField
                                        id="user"
                                        name="user"
                                        label="Usuario"
                                        value={login.user ? login.user : ""}
                                        margin="dense"
                                        type="text"
                                        onChange={this.setLoginValue}
                                        helperText={errorsLogin.user && errorsLogin.user !== "" ? errorsLogin.user : ""}
                                        error={errorsLogin.user && errorsLogin.user !== "" ? true : false}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl style={{ width: '100%' }}>
                                    <TextField
                                        id="password"
                                        name="password"
                                        label="Contraseña"
                                        value={login.password ? login.password : ""}
                                        margin="dense"
                                        type="password"
                                        onChange={this.setLoginValue}
                                        helperText={login.password && login.password !== "" ? login.password : ""}
                                        error={errorsLogin.password && errorsLogin.password !== "" ? true : false}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button style={{ float: 'right', backgroundColor: '#0095a8' }}
                                    disabled={saving}
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.login}>
                                <PermIdentityIcon />Validar usuario</Button>
                            </Grid>
                        </Grid>
                    </Card>
                    </>
                }

                { this.state.loggedIn && 
                <>
                <Card style={{ margin: '5em 8vw', padding: '1em' }}>

                        <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <span style={{ weight: 'bold', fontSize: '20px', color: '#0095a8' }}>Formulario de incidencia de proveedor: {login.user} </span>
                        </Grid>
                        <Grid item xs={12}>
                            <span style={{ weight: 'bold', fontSize: '16px', color: 'darkgrey' }}>Datos de identificación del cliente:</span>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl style={{ width: '100%' }}>
                                <TextField
                                    id="cif"
                                    name="cif"
                                    label="CIF del Cliente / Farmacia"
                                    value={form.cif ? form.cif : ""}
                                    margin="dense"
                                    type="text"
                                    onChange={this.setValue}
                                    helperText={errors.cif && errors.cif !== "" ? errors.cif : ""}
                                    error={errors.cif && errors.cif !== "" ? true : false}
                                />
                            </FormControl>
                        </Grid>
                            <Grid item xs={12}>
                                <span style={{ weight: 'bold', fontSize: '16px', color: 'darkgrey' }}>Clasificación de la incidencia:</span>
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl component="fieldset"  style={{ width: '100%', fontSize: '16px' }} error={errors.clasificacion !== ''}>
                                    <RadioGroup
                                        style={{ fontSize: '16px'}}
                                        aria-label="clasificacion"
                                        name="clasificacion"
                                        value={form.clasificacion ? form.clasificacion : ""}
                                        onChange={this.setValue}
                                    >
                                    {clasifications.map(clasification => {
                                        return (
                                            <FormControlLabel style={{ fontSize: '16px'}} key={`clasification_${clasification.codex}`} value={clasification.codex} control={<Radio color="default" />} label={<Typography style={{ fontSize: '16px',color: 'balck' }}>{clasification[HDT_DENO]}</Typography>} />
                                        )
                                    })}       

                                    </RadioGroup>
                                    <FormHelperText>{errors.clasificacion}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl style={{ width: '100%' }}>
                                    <TextField
                                        multiline
                                        id="obs"
                                        name="obs"
                                        label="Descripción de la incidencia"
                                        value={form.obs ? form.obs : ""}
                                        margin="dense"
                                        type="text"
                                        onChange={this.setValue}
                                        helperText={errors.obs && errors.obs !== "" ? errors.obs : ""}
                                        error={errors.obs && errors.obs !== "" ? true : false}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <span style={{ weight: 'bold', fontSize: '16px', color: 'darkgrey' }}>Añadir imágenes (Opcional):</span>
                            <FormControl style={{ width: '100%', padding: '5px' }}>
                                <DropzoneArea
                                    key = {dropzonekey}
                                    acceptedFiles={['image/*']}
                                    maxFileSize={6000000}
                                    dropzoneClass="dropzone"
                                    dropzoneText={"Arrastre o pulse para añadir hasta 3 imágenes"}
                                    showPreviews={true}
                                    showPreviewsInDropzone={false}
                                    showFileNamesInPreview={false}
                                    previewText="Previsualización:"
                                    onChange={this.onChangeFiles.bind(this)}
                                    showAlerts={false}
                                />
                            </FormControl>
                        </Grid>
                            <Grid item xs={12}>
                                <Button style={{ float: 'right', backgroundColor: '#0095a8' }}
                                    disabled={saving || !captchaOK}
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.save}>
                                <Save />Enviar incidencia</Button>
                                    <ReCAPTCHA style={{ float: 'right'}}
                                        ref={this.recaptchaRef}
                                        size="compact"
                                        sitekey="6LdtTX0UAAAAAJ39UJw68nBwq3YJ3ZewB_9eld96"
                                        onChange={this.onCaptchaOK}
                                    />
                            </Grid>

                    </Grid>
                </Card>
                </>
            }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    main: state.main
});

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(mainActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormProvider);